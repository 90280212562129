<template>
    <div>
        <st-filters-pagination
            stateModule="applications/list"
            :filters="filters"
            :total="total"
            @change="doFilter"
            :header="header"
            :clearButtonInPrimaryFilters="true"
        >
            <slot></slot>
        </st-filters-pagination>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { ApplicationModel } from '@/modules/applications/models/application-model';
    import { ApplicationsPermissions } from '@/modules/applications/applications-permissions';

    const { fields, statuses } = ApplicationModel;

    export default {
        name: 'ApplicationsListFilter',
        props: {
            assigned: Boolean,
            isSuperAdmin: Boolean
        },
        data() {
            return {
                fields
            }
        },
        computed: {
            ...mapGetters({
                total: 'applications/list/total',
                currentUser: 'auth/currentUser',
            }),
            hasPermissionToOrganisationsApplication() {
                return new ApplicationsPermissions(this.currentUser).readStaff;
            },
            hasPermissionToStaffOwnApplications() {
                return new ApplicationsPermissions(this.currentUser).readOwnStaff;
            },
            hasPermissionToReadStaff() {
                return this.hasPermissionToOrganisationsApplication || this.hasPermissionToStaffOwnApplications;
            },
            header() {
                return this.$t('APPLICATION.LIST.TABLE_HEADER');
            },
            filters() {
                let filterFields = [];
                if (this.hasPermissionToReadStaff) {
                    const filterInternalStatus = Object.assign({}, this.fields.internal_status);
                    filterInternalStatus.options = this.fields.internal_status.options.filter((el) => el?.display !== false);

                    filterFields = [
                        // Primary
                        this.fields.quick_search_values,
                        this.fields.beneficiary,
                        this.fields.beneficiary_legal,
                        this.fields.cadastral_number,
                        filterInternalStatus,
                        //Secondary
                        this.fields.application_date,
                        this.fields.identification_number,
                        this.fields.territorial_administrative_unit_id,
                        this.fields.document_name,
                        this.fields.assigned_staff_user_name,
                        this.fields.last_review_date,
                    ];
                } else {
                    filterFields = [
                        // Primary
                        this.fields.quick_search_values,
                        this.fields.status,
                        this.fields.application_date,
                        //Secondary
                        this.fields.beneficiary,
                        this.fields.beneficiary_legal,
                        this.fields.identification_number,
                        this.fields.document_name,
                    ]
                }

                const filterSchema = new FilterSchema(filterFields);
                return filterSchema.fields;
            }
        },
        methods: {
            ...mapActions({
                doFetch: 'applications/list/doFetch',
            }),
            refresh() {
                this.doFilter(this.params);
            },
            async doFilter(params) {
                this.params = params;
                const payload = {
                  loadStaff: this.hasPermissionToReadStaff,
                  params,
                };

                //get assigned applications or the ones which need to be signed
                if (this.assigned) {
                    payload.params.assigned = true;
                }

                this.doFetch(payload);
            },
            staffStatusOptions(statusOptions) {
                return statusOptions.filter(option => option.name !== 'draft')
                                    .sort((a, b) => a.sortkey - b.sortkey );
            }
        },
        created() {
            const statusOptions = this.hasPermissionToReadStaff ? statuses : statuses.filter((el) => !el.internal);
            if (this.hasPermissionToReadStaff) {
                this.fields.internal_status.options = this.staffStatusOptions(statusOptions);
            } else {
                this.fields.status.options = statusOptions;
                this.fields.application_date.primary = true;
                this.fields.beneficiary.primary = false;
                this.fields.beneficiary_legal.primary = false;
                this.fields.identification_number.primary = false;
            }
        }
    };
</script>
