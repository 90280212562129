import ApiService from '@/shared/services/api.service';

export class ApplicationsService {
    static list(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/applications', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static listAll(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/applications/listAll', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static listStaff(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/staff-applications', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static find(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/applications/${id}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static findStaff(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/staff-applications/${id}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    }

    static create(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/applications?echo=true', { ...data } )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    }

    static update(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/applications/${id}`, { ...data })
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static updateStaff(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/staff-applications/${id}`, { ...data })
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static findFiles(id, token, params) {
        return new Promise((resolve, reject) => {
            if (token) {
                ApiService.query(`/files/${id}`, { params, headers: {
                    Accept: 'application/json',
                    'x-file-access-token': token,
                }})
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
            } else {
                resolve([]);
            }
        });
    }

    static assignStaff(params) {
        const { applicationId, payload } = params;
        return new Promise((resolve, reject) => {
            ApiService.patch(`/staff-applications/${applicationId}/assign`, payload)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static approveDocument(id) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/staff-applications/${id}/approve`, {})
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static rejectDocument(id) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/staff-applications/${id}/reject`, {})
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static signDocument(id, action) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/staff-applications/${id}/${action}/sign`, {})
                .then((response) => {
                    // TODO: Remove after BE imp
                    if (response.data.success) {
                        resolve(response.data);
                    } else {
                        reject(response.data);
                    }
                })
                .catch((error) => reject(error));
        });
    }

    static signDocumentCitizen(id, review) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/applications/${id}/sign${review ? '?review=true' : ''}`, {})
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static signDocumentApplicantUser(id) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/applications/${id}/sign-output-document`, {})
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static validateDocumentSign(payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/staff-applications/${payload.applicationId}/confirm-signing`, {
                pin: payload.pin,
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static validateDocumentSignCitizen(payload) {
        return new Promise((resolve, reject) => {
            const { pin, review } = payload;
            const body = {
                pin,
            };
            if (review) {
                body.review = review;
            }
            ApiService.put(`/applications/${payload.applicationId}/confirm-signing`, body)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static validateDocumentSignApplicantUser(payload) {
        return new Promise((resolve, reject) => {
            const { pin } = payload;
            const body = {
                pin,
            };
            ApiService.put(`/applications/${payload.applicationId}/confirm-signing-output-document`, body)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static checkSigningStatus(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/applications/${id}/check-signing-status`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static checkStaffSigningStatus(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/staff-applications/${id}/check-signing-status`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static downloadDocument(token, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/files/${id}`, {
                'x-file-access-token': token,
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static calculateTaxes(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/application-types/${id}/all-taxes`, data)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static calculateTaxesForApplicationType(data) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/application-types/${data.id}/taxes`, data)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static updatePaymentStatus(payload) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/applications/${payload.id}`, {is_paid: payload.is_paid})
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static finalizeApplication(id) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/applications/${id}/send`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static findUserActions(params){
        return new Promise((resolve, reject) => {
            ApiService.query('/user-actions', { params })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static previewDocument(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/staff-applications/${id}/preview-document`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static previewDocumentByCitizen(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/applications/${id}/preview-document`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static generateFinalDocument(id, action) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/staff-applications/${id}/${action}/generate-document`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static upload(token, files) {
        return new Promise((resolve, reject) => {
           ApiService.post('/files?echo=true', files, {
               'content-type': 'multipart/form-data',
               'x-file-access-token': token,
           })
               .then((response) => resolve(response.data))
               .catch((error) => reject(error));
        });
    }

    static pay({ id, data, payment_initiated }) {
        const url = payment_initiated ? `applications/${id}/refresh-payment-url` : `/applications/${id}/pay`;
        return new Promise((resolve, reject) => {
            ApiService.post(url, data )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    }

    static checkPaymentStatus(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/applications/${id}/check-payment-status`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static initializeOfflineSigning(id, filesLocation) {
        return new Promise((resolve, reject) => {
            ApiService.post( `/applications/${id}/initialize-offline-sign?filesLocations=${filesLocation}` )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    }

    static checkOfflineSigning(id, new_pdf_id) {
        return new Promise((resolve, reject) => {
            ApiService.post( `/applications/${id}/check-offline-sign`, { new_pdf_id })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    }

    static finalizeOfflineSigning(id, newPdfId) {
        return new Promise((resolve, reject) => {
            ApiService.post( `/applications/${id}/finish-offline-sign/${newPdfId}` )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    }

    static enrollment(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/enrollment/person', { ...data })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    }

    static paymentCallback(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/payments/callback', params)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    }

    static paymentsList(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/payments/all', params)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    }

    static getSystemMetadata() {
        return new Promise((resolve, reject) => {
            ApiService.get('/applications/system-metadata')
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    }

    static getActivities(id) {
        return new Promise((resolve, reject) => {
            ApiService.query(`user-messages/identification/${id}`, {
                params: {
                    sort: '-created_date',
                    limit: 100
                }
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => reject(error));
        });
    }

    static sendStaffComment(params) {
        return new Promise((resolve, reject) => {
            ApiService.post( `staff-applications/${params.id}/comment`, { ...params.payload })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    }
    static sendComment(params) {
        return new Promise((resolve, reject) => {
            ApiService.post( `applications/${params.id}/comment`, { ...params.payload })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    }

    static addComments(params) {
        const { applicationId, payload } = params;
        return new Promise((resolve, reject) => {
            ApiService.post(`/staff-applications/${applicationId}/comment`, payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    }

    static returnDocument(params) {
        const { applicationId, payload } = params;
        return new Promise((resolve, reject) => {
            ApiService.patch(`/staff-applications/${applicationId}/return-document`, payload)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static findRelatedFiles(id){
        return new Promise((resolve, reject) => {
            ApiService.query(`/staff-applications/${id}/related-files`)
            .then(({data}) => {
                resolve(data);
            })
            .catch((error) => reject(error));
        })
    }

    static sendClarifications(id) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/staff-applications/${id}/review`, {})
                .then(({data}) => {
                    resolve(data);
                })
                .catch((error) => reject(error));
        })
    }

    static sendReview(id, citizen_review) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/applications/${id}/send-review`, { citizen_review })
                .then(({data}) => {
                    resolve(data);
                })
                .catch((error) => reject(error));
        })
    }

    static checkGenerationStatus(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/applications/${id}/check-generation-status`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        })
    }

    static signDocumentBulk(payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/staff-applications/bulk-sign`, { application_ids: payload })
                .then(({data}) => resolve(data))
                .catch((error) => reject(error));
        });
    }

    static checkStaffBulkSigningStatus(payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/staff-applications/check-bulk-signing-status`, { application_ids: payload})
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static validateBulkDocumentSign(payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/staff-applications/confirm-bulk-signing`, {
                pin: payload.pin,
                request_id: payload.applicationId
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static findUrbanismRegulations(uat_ids){
        return new Promise((resolve, reject) => {
            ApiService.query(`/urbanism-regulation-zones/groups?uat_ids=${uat_ids.join(',')}`)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => reject(error));
        })
    }

    static findUrbanismRegulationEntriesByZone(zone_id){
        return new Promise((resolve, reject) => {
            ApiService.query(`/urbanism-regulations`, {
                params: {
                    query: `zone_id eq ${zone_id}`
                }
            })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => reject(error));
        })
    }

    static manualCompletion(id, data, forceUpdate){
        return new Promise((resolve, reject) => {
            const queryParam = forceUpdate ? `?force=true` : '';
            ApiService.patch(`/staff-applications/${id}/manual-completion${queryParam}`, { ...data })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        })
    }

    static findByDocumentSeries(series) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/applications/find?series=${series}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static findByDocumentSeriesPublic(series) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/applications/findAll?series=${series}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static findBeneficiary(id){
        return new Promise((resolve, reject) => {
            ApiService.query(`/beneficiaries/${id}`)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => reject(error));
        });
    }

    static deleteApplication(id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/applications/${id}/draft`)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
    }

    static removeApplicationDoc(payload) {
        const {file_id, app_id} = payload;
        return new Promise((resolve, reject) => {
            ApiService.patch(`staff-applications/${app_id}/remove-document`, { file_id })
            .then((data) => resolve(data))
            .catch((error) => reject(error));
        });
    }

    static getAssignedStaffAccount(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`users/${id}`)
                .then(({data}) => resolve(data))
                .catch((error) => reject(error));
        });
    }

    static signAttachmentsBulk({application_id, file_ids}) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/staff-applications/attachments-bulk-sign`, { application_id, file_ids })
                .then(({data}) => resolve(data))
                .catch((error) => reject(error));
        });
    }

    static downloadCompression({ application_id, files }) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/staff-applications/${application_id}/download-compression`, { files })
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static validateBulkAttachmentsSign(payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/staff-applications/confirm-attachments-bulk-signing`, {
                pin: payload.pin,
                application_id: payload.applicationId,
                request_id: payload.file_ids
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static checkApplicationPaymentStatus(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`payments/application/${id}/bt-url-check`)
                .then(({data}) => resolve(data))
                .catch((error) => reject(error));
        });
    }

    static getLayers(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/layers/me', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getPublicLayers(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/public-layers/', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static closeApplication(id) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/staff-applications/${id}/close-application`, {})
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        })
    }

    static createPaymentLink(id, amount, email) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/staff-applications/${id}/create-payment-link`, { amount, email })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        })
    }

    static startProcess(id) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/staff-applications/${id}/start-process`, {})
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        })
    }

    static getApplicationTypesByOrganisation() {
        return new Promise((resolve, reject) => {
            ApiService.query('/application-types/organisation', {})
                .then((response)=> resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static sealAttachmentsBulk(id) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/staff-applications/attachments-bulk-sealing`, { id })
                .then(({data}) => resolve(data))
                .catch((error) => reject(error));
        });
    }

    static validateGisFile({application_id, file_id}) {
        return new Promise((resolve, reject) => {
            ApiService.post( `/gis-files`, { application_id, file_id })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    }

    static getPredefinedReports() {
        return new Promise((resolve, reject) => {
            ApiService.query('/predefined-reports', {})
                .then((response)=> {
                    resolve(response.data.data)
                })
                .catch((error) => reject(error));
        });
    }

    static savePredefinedReport(payload) {
        return new Promise((resolve, reject) => {
            ApiService.post( `/predefined-reports`, payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    }

    static updatePredefinedReport(payload, id) {
        return new Promise((resolve, reject) => {
            ApiService.put( `/predefined-reports/${id}`, payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    }

    static generateApplicationsCsv(payload) {
        return new Promise((resolve, reject) => {
            ApiService.post( `/reported-applications/generate/csv`, payload, { responseType: 'blob' })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    }
}
